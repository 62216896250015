import {
  combineReducers,
} from "redux";

import {
  reducer as reduxFormReducer,
} from "redux-form";
import SingleRequestReducer from "../features/newRequestSlice";
import RequestListReducer from "../features/requestListSlice";
import SubstitutionReducer from "../features/substitutionSlice";
import CommunicationReducer from "../features/communicationSlice";
import StatisticReducerSlices from "../features/statisticsSlice";
import MasterdataReducerSlices from "../features/masterdataSlice";
import UserSlice from "../features/userSlice";
import ErrorReducerSlice from "../features/errorSlice";
import loadingSlice from "store/features/loadingSlice";
import wizardSlice from "pages/Wizard/wizardSlice";
import patientSlice from "pages/Wizard/forms/patient/patientSlice";
import medicineFormSlice from "pages/Wizard/forms/med/medicine/medicineSlice";
import diagnosisSlice from "pages/Wizard/forms/med/Diagnosis/diagnosisSlice";
import dosisPlanSlice from "pages/Wizard/forms/med/Dosis/dosisPlanSlice";
import defaultMedicineSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/DefaultMedicineForm/defaultMedicineSlice";
import treatmentPlanSlice from "pages/Wizard/forms/med/Treatment/treatmentPlanSlice";
import doctorSlice from "pages/Wizard/forms/doctor/doctorSlice";
import specialMedSlice from "pages/Wizard/forms/med/AdditionalInformation/specialMedSlice";
import praluentSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/PraluentForm/praluentSlice";
import BrukinsaSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/BrukinsaForm/BrukinsaSlice";
import EnhertuSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/EnhertuForm/EnhertuSlice";
import QuviviqSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/QuviviqForm/QuviviqSlice";
import BrukinsaCllSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/BrukinsaFormCll/BrukinsaCllSlice";
import CalquenceSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/Calquence/CalquenceSlice";
import ImbruvicaSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/Imbruvica/ImbruvicaSlice";
import ImfinziSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/Imfinzi/ImfinziSlice";
import ImjudoSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/Imjudo/ImjudoSlice";
import TagrissoSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/Tagrisso/TagrissoSlice";
import LynparzaSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/Lynparza/LynparzaSlice";
import IbranceSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/Ibrance/IbranceSlice";
import SomatulineSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/Somatuline/SomatulineSlice";
import CabometyxSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/Cabometyx/CabometyxSlice";
import SpravatoSlice from "pages/Wizard/forms/med/AdditionalInformation/forms/Spravato/SpravatoSlice";


const rootReducer = combineReducers({
  wizard: combineReducers({
    wizardModel: wizardSlice,
    patient: patientSlice,
    doctor: doctorSlice,
    medicine: combineReducers({
      medForm: medicineFormSlice,
      diagnosisForm: diagnosisSlice,
      dosisPlanForm: dosisPlanSlice,
      additionalInformation: combineReducers({
        defaultMedicineForm: defaultMedicineSlice,
        specialMed: specialMedSlice,
        praluentForm: praluentSlice,
        brukinsaCllForm: BrukinsaCllSlice,
        brukinsaForm: BrukinsaSlice,
        enhertuForm: EnhertuSlice,
        quviviqForm: QuviviqSlice,
        imbruvicaForm: ImbruvicaSlice,
        calquenceForm: CalquenceSlice,
        imfinziForm: ImfinziSlice,
        imjudoForm: ImjudoSlice,
        tagrissoForm: TagrissoSlice,
        lynparzaForm: LynparzaSlice,
        ibranceForm: IbranceSlice,
        somatulineForm: SomatulineSlice,
        cabometyxForm: CabometyxSlice,
        spravatoForm: SpravatoSlice,
      }),
      treatmentPlanForm: treatmentPlanSlice,
    }),
  }),
  form: reduxFormReducer,
  singleRequest: SingleRequestReducer,
  requestList: RequestListReducer,
  substitution: SubstitutionReducer,
  communication: CommunicationReducer,
  statistics: StatisticReducerSlices,
  userinformation: UserSlice,
  masterdata: MasterdataReducerSlices,
  error: ErrorReducerSlice,
  loading: loadingSlice,
});
export default rootReducer;

