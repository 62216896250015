import React, {useEffect} from "react";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {
  connect,
} from "react-redux";


const SnackbarComponent=(props) => {
  const {
    feedbackType,
    feedbackMessage,
    openMessage,
    messages,
  } = props;

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  useEffect(()=>{
    console.log("SnackbarComponent", feedbackMessage, openMessage, feedbackType);
    if (feedbackMessage) {
      enqueueSnackbar(t(feedbackMessage), {variant: feedbackType, anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      }, autoHideDuration: 6000});
    }
  }, [feedbackMessage, openMessage, feedbackType, messages]);

  return (<>
  </>
  );
};
const mapStateToProps=(state) => {
  return {
    messages: state.error.messages,
    openMessage: state.error.openMessage,
    feedbackType: state.error.feedbackType,
    feedbackMessage: state.error.feedbackMessage,
  };
};


export default connect(mapStateToProps)(SnackbarComponent);
