import {Grid, Typography} from "@mui/material";
import {useIsInViewport} from "pages/Wizard/containers/utils";
import {onViewChange, isFormVisible} from "pages/Wizard/wizardSlice";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {selectDiagnosisValues} from "../Diagnosis/diagnosisSlice";

import {
  selectMedicineValues,
  onChangeKVVLegalState,

} from "../medicine/medicineSlice";

import {
  getPreselectedStudy,

} from "pages/Wizard/wizardSlice";


import {DefaultMedicineForm} from "./forms/DefaultMedicineForm/DefaultMedicineForm";
import PraluentForm from "./forms/PraluentForm/PraluentForm";
import BrukinsaForm from "./forms/BrukinsaForm/BrukinsaForm";
import {
  checkIsBrukinsa,
  checkIsBrukinsaCll,
  checkIsQuviviq,
  checkIsPraluent,
  checkIsEnhertu,
  checkIsImfinzi,
  checkIsImjudo,
  checkIsCalquence,
  checkIsImbruvica,
  checkIsTagrisso,
  checkIsLynparza,
  checkIsIbrance,
  checkIsSomatuline,
  checkIsCabometyx,
  checkIsSpravato,
} from "./isSpecialMed";

import {onChangeDosisValue} from "./../Dosis/dosisPlanSlice";
import QuviviqForm from "./forms/QuviviqForm/QuviviqForm";
import EnhertuForm from "./forms/EnhertuForm/EnhertuForm";
import CalquenceForm from "./forms/Calquence/CalquenceForm";
import ImfinziForm from "./forms/Imfinzi/ImfinziForm";
import ImjudoForm from "./forms/Imjudo/ImjudoForm";
import TagrissoForm from "./forms/Tagrisso/TagrissoForm";
import LynparzaForm from "./forms/Lynparza/LynparzaForm";
import IbranceForm from "./forms/Ibrance/IbranceForm";
import SomatulineForm from "./forms/Somatuline/SomatulineForm";
import CabometyxForm from "./forms/Cabometyx/CabometyxForm";
import SpravatoForm from "./forms/Spravato/SpravatoForm";

import ImbruvicaForm_CLL from "./forms/Imbruvica/ImbruvicaForm_CLL";
import ImbruvicaForm_MW from "./forms/Imbruvica/ImbruvicaForm_MW";
import ImbruvicaForm_MCL from "./forms/Imbruvica/ImbruvicaForm_MCL";

import {onChangeSpecialMedValue, SpecialMedAdditionalInformation} from "./specialMedSlice";
import BrukinsaFormCll from "./forms/BrukinsaFormCll/BrukinsaFormCll";
import {onChangeTreatmentValue} from "../Treatment/treatmentPlanSlice";


export const FormToShow = (props) => {
  const {t}=useTranslation();
  const medicineValues=UseAppSelector(selectMedicineValues);
  const diagnosisValues=UseAppSelector(selectDiagnosisValues);
  const isVisibleValues = UseAppSelector(isFormVisible);
  const [formToShow, setFormToShow]=useState(<DefaultMedicineForm/>);

  const dispatch=UseAppDispatch();
  const handleValueChange = (field:string, value:any)=>dispatch(onChangeSpecialMedValue({field, value}));
  const handleDosisValueChange = (field:string, value:any)=>dispatch(onChangeDosisValue({field, value}));
  const handleChangeTreatmentValue = (field: string, value: any) => dispatch(onChangeTreatmentValue({field, value}));
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));

  const isVisible=useIsInViewport(props.refProps);
  useEffect(()=>{
    isVisibleValues.additionalInformation!==isVisible&&dispatch(onViewChange({field: "additionalInformation", value: isVisible}));
  }, [isVisible]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const isInDatabase = medicineValues.substances?.length > 0;
      console.debug("isInDatabase", isInDatabase);

      const isPraluent = await checkIsPraluent(
          diagnosisValues.diagnosis,
          medicineValues.required_drug,
      );
      const isBrukinsa = await checkIsBrukinsa(
          medicineValues.required_drug,
      );
      const isBrukinsaCll = await checkIsBrukinsaCll(
          medicineValues.required_drug,
          diagnosisValues.diagnosis);
      const isEnhertu = await checkIsEnhertu(
          medicineValues.required_drug,
      );
      const isQuviviq = await checkIsQuviviq(
          medicineValues.required_drug,
          diagnosisValues.diagnosis,
      );
      const isImfinzi = await checkIsImfinzi(
          medicineValues.required_drug,
      );
      const isImjudo = await checkIsImjudo(
          medicineValues.required_drug );
      const isTagrisso = await checkIsTagrisso(
          medicineValues.required_drug,
      );
      const isLynparza = await checkIsLynparza(
          medicineValues.required_drug,
      );
      const isIbrance = await checkIsIbrance(
          medicineValues.required_drug,
      );
      const isSomatuline = await checkIsSomatuline(
          medicineValues.required_drug,
      );
      const isCabometyx = await checkIsCabometyx(
          medicineValues.required_drug,
      );
      const isSpravato = await checkIsSpravato(
          medicineValues.required_drug,
      );

      const isCalquence = await checkIsCalquence(
          medicineValues.required_drug,
          diagnosisValues.diagnosis,
      );
      const isImbruvicaFrom = await checkIsImbruvica(
          medicineValues.required_drug,
          diagnosisValues.diagnosis,
      );


      if (isInDatabase && isLynparza) {
        handleValueChange("special_med_type", "Lynparza");
        setFormToShow(<LynparzaForm />);
      } else if (isInDatabase && isTagrisso) {
        handleValueChange("special_med_type", "Tagrisso");
        handleValueChange("special_med_additional_information", SpecialMedAdditionalInformation.TAGRISSO);
        setFormToShow(<TagrissoForm/>);
      } else if (isInDatabase && isIbrance) {
        handleValueChange("special_med_type", "Ibrance");
        handleValueChange("special_med_additional_information", SpecialMedAdditionalInformation.IBRANCE);
        setFormToShow(<IbranceForm/>);
      } else if (isInDatabase && isSomatuline) {
        handleValueChange("special_med_type", "Somatuline");
        setFormToShow(<SomatulineForm/>);
      } else if (isInDatabase && isCabometyx) {
        handleValueChange("special_med_type", "Cabometyx");
        setFormToShow(<CabometyxForm/>);
      } else if (isInDatabase && isSpravato) {
        handleValueChange("special_med_type", "Spravato");
        setFormToShow(<SpravatoForm/>);
      } else if (isInDatabase && isImjudo) {
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        handleValueChange("special_med_type", "Imjudo");
        handleValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);
        setFormToShow(<ImjudoForm />);
      } else if (isInDatabase && isImfinzi) {
        handleValueChange("special_med_type", "Imfinzi");
        setFormToShow(<ImfinziForm />);
        dispatch(getPreselectedStudy({}));
      } else if (isInDatabase && isCalquence) {
        handleValueChange("special_med_type", "Calquence");
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        handleDosisValueChange("dosis", t("calquence.dose"));

        setFormToShow(<CalquenceForm />);
      } else if (isInDatabase && isImbruvicaFrom !== null) {
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        dispatch(onChangeKVVLegalState({
          limitatio_approval: false,
          specialist_info: false,
        }));
        handleValueChange("special_med_type", "Imbruvica");
        handleValueChange("special_med_additional_information", SpecialMedAdditionalInformation.IMBRUVICA);
        if (isInDatabase && isImbruvicaFrom === 0) {
          setFormToShow(<ImbruvicaForm_CLL />);
        } else if (isImbruvicaFrom === 1) {
          setFormToShow(<ImbruvicaForm_MW />);
        } else if (isImbruvicaFrom === 2) {
          setFormToShow(<ImbruvicaForm_MCL />);
        }
      } else if (isInDatabase && isPraluent) {
        handleValueChange("special_med_type", "Praluent");
        handleValueChange("special_med_data", null);
        setFormToShow(<PraluentForm />);
        dispatch(getPreselectedStudy({}));
      } else if (isInDatabase && isBrukinsaCll) {
        handleValueChange("special_med_type", "BrukinsaCll");
        handleDosisValueChange("dosis", t("brukinsa.dose"));
        handleValueChange("special_med_data", null);
        handleChangeTreatmentValue("limitatio_approval", false);
        handleChangeTreatmentValue("specialist_info", false);
        setFormToShow(<BrukinsaFormCll />);
        dispatch(getPreselectedStudy({}));
      } else if (isInDatabase && isBrukinsa) {
        handleValueChange("special_med_type", "Brukinsa");
        handleValueChange("special_med_data", null);
        setFormToShow(<BrukinsaForm />);
        dispatch(getPreselectedStudy({}));
      } else if (isInDatabase && isQuviviq) {
        handleValueChange("special_med_type", "Quviviq");
        if (medicineValues.required_drug && medicineValues.required_drug.indexOf("50") > -1) {
          handleDosisValueChange("dosis", t("quviviq.dose_50mg"));
        } else {
          handleDosisValueChange("dosis", t("quviviq.dose_20mg"));
        }
        setFormToShow(<QuviviqForm />);
        dispatch(getPreselectedStudy({}));
      } else if (isInDatabase && isEnhertu) {
        handleValueChange("special_med_type", "Enhertu");
        handleDosisValueChange("dosis", t("enhertu.dose"));
        setFormToShow(<EnhertuForm />);
      } else {
        handleValueChange("special_med_type", "");
        handleValueChange("special_med_data", null);
        setFormToShow(<DefaultMedicineForm/>);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [
    medicineValues.required_drug,
    medicineValues.lastloaded,
    diagnosisValues.diagnosis,
    medicineValues.substances?.length,
  ]);


  return (
    <Grid ref={props.refProps} container direction="column" spacing={3} id="additionalInformation">
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5" >
            {t(props.label)}
          </Typography>
        </Grid>
        {formToShow}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormToShow);
