import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Select,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  MenuItem,
  Typography,
  InputLabel,
} from "@mui/material";
import {useLocation} from "react-router-dom";
import {onChangeKVVLegalState} from "../../../medicine/medicineSlice";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";
import {onChangeDiagnosisValue} from "../../../Diagnosis/diagnosisSlice";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {de} from "date-fns/locale";
import {setCombinedMedication} from "pages/Wizard/wizardSlice";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";

import {
  onChangeSpravatoValue,
  selectSpravatoValues,
} from "./SpravatoSlice";

import DrugPlanForm from "./DrugPlanForm";

const SpravatoForm = (props) => {
  const {t} = useTranslation();
  const dispatch = UseAppDispatch();
  const SpravatoValues = UseAppSelector(selectSpravatoValues);
  const params = useLocation();
  const bagApprovedInstitutions = t("spravato.bagApprovedInstitutionsList").split(",");
  const handleValueChangeDiagnosis = (field:string, value:any)=>dispatch(onChangeDiagnosisValue({field, value}));
  const regex = /\d+$/;
  const isEdit = regex.test(params.pathname);

  const cgiSOptions = [
    {value: 0, label: t("spravato.cgiS.notAssessed")},
    {value: 1, label: t("spravato.cgiS.normal")},
    {value: 2, label: t("spravato.cgiS.borderline")},
    {value: 3, label: t("spravato.cgiS.mildlyIll")},
    {value: 4, label: t("spravato.cgiS.moderatelyIll")},
    {value: 5, label: t("spravato.cgiS.severelyIll")},
    {value: 6, label: t("spravato.cgiS.verySeverelyIll")},
    {value: 7, label: t("spravato.cgiS.extremelyIll")},
  ];
  console.debug("cgiSOptions", cgiSOptions);
  useEffect(() => {
    dispatch(setCombinedMedication(
        {
          "medication": SpravatoValues.seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant,
        }));
  }, [SpravatoValues.seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant]);
  useEffect(() => {
    // Set default dosis value
    if (SpravatoValues.firstSevereTreatmentResistantMajorDepressiveEpisode) {
      dispatch(onChangeDosisValue({field: "dosis", value: t("spravato.dose")}));
    } else if (!isEdit) {
      dispatch(onChangeDosisValue({field: "dosis", value: ""}));
    }
    if (SpravatoValues.firstAlternativeIndication) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
      dispatch(onChangeTreatmentValue({field: "specialist_info", value: false}));
      dispatch(onChangeTreatmentValue({field: "limitatio_approval", value: false}));
    } else if (
      SpravatoValues.firstSevereTreatmentResistantMajorDepressiveEpisode
    ) {
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      dispatch(onChangeTreatmentValue({field: "limitatio_approval", value: false}));
      dispatch(onChangeTreatmentValue({field: "specialist_info", value: true}));
    }
    if (
      /*
        Change to the only visible fields
      */
      SpravatoValues.firstSevereTreatmentResistantMajorDepressiveEpisode &&
      SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist &&
      SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
      SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
      SpravatoValues.fourthPatientIsAged18to74YearsInclusive &&
      SpravatoValues.fourthPatientHasPreviouslyReceived2DifferentAntidepressants &&
      SpravatoValues.fifthPatientHasPreviouslyReceived1Augmentation &&
      SpravatoValues.sixthCGISBeforeTreatmentWithSPRAVATO5 &&
      SpravatoValues.seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable
    ) {
      // VARIANTS
      if (SpravatoValues.thirdProgressReviewAfter4Weeks === true && SpravatoValues.ninthCGICheckBox === false) {
        // nothing to do
      } else if (SpravatoValues.thirdProgressReviewAfter4MonthsOfTherapy &&
        SpravatoValues.eightCGISBeforeTreatmentWithSPRAVATO5 === false) {
        // nothing to do
      } else if (SpravatoValues.thirdProgressReviewAfter7MonthsofTherapy &&
        SpravatoValues.tenthCGICheckBox === false) {
        // nothing to do
      } else if (SpravatoValues.thirdTherapyDurationOf10MonthsOrMore) {
        // nothing to do
      } else {
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        dispatch(onChangeTreatmentValue({field: "limitatio_approval", value: true}));
        dispatch(onChangeTreatmentValue({field: "specialist_info", value: true}));
      }
    }
  }, [
    params.pathname,
    SpravatoValues.firstSevereTreatmentResistantMajorDepressiveEpisode,
    SpravatoValues.firstAlternativeIndication,
    SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist,
    SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution,
    SpravatoValues.fourthPatientIsAged18to74YearsInclusive,
    SpravatoValues.fourthPatientHasPreviouslyReceived2DifferentAntidepressants,
    SpravatoValues.fifthPatientHasPreviouslyReceived1Augmentation,
    SpravatoValues.sixthCGISBeforeTreatmentWithSPRAVATO5,
    SpravatoValues.seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable,
    SpravatoValues.secondBagApprovedInstitution,
    SpravatoValues.thirdProgressReviewAfter4MonthsOfTherapy,
    SpravatoValues.eightCGISBeforeTreatmentWithSPRAVATO5,
    SpravatoValues.thirdProgressReviewAfter4Weeks,
    SpravatoValues.ninthCGICheckBox,
    SpravatoValues.thirdTherapyDurationOf10MonthsOrMore,
    SpravatoValues.eleventhCGICheckBox,
    SpravatoValues.thirdProgressReviewAfter7MonthsofTherapy,
    SpravatoValues.tenthCGICheckBox,
  ]);


  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={de}>

      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormControl margin="normal">
            <FormLabel>{""}</FormLabel>
            <RadioGroup
              onChange={(event, value) => {
                if (value === "firstSevereTreatmentResistantMajorDepressiveEpisode") {
                  handleValueChangeDiagnosis("diagnosis", t("spravato.diagnosis"));
                  dispatch(onChangeSpravatoValue({formData: {firstSevereTreatmentResistantMajorDepressiveEpisode: true}}));
                } else if (value === "firstAlternativeIndication") {
                  dispatch(onChangeSpravatoValue({formData: {firstAlternativeIndication: true}}));
                }
              }}>
              <FormControlLabel
                value="firstSevereTreatmentResistantMajorDepressiveEpisode"
                control={<Radio
                  checked={SpravatoValues.firstSevereTreatmentResistantMajorDepressiveEpisode}
                  data-cy="spravato_default" />}
                label={t("spravato.firstSevereTreatmentResistantMajorDepressiveEpisode")}
              />
              <FormControlLabel
                value="firstAlternativeIndication"
                control={<Radio
                  checked={SpravatoValues.firstAlternativeIndication}
                  data-cy="firstAlternativeIndication" />}
                label={t("spravato.firstAlternativeIndication")}
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{m: 1}}/>
        </Grid >
        {/*
          2 Part
        */
        }
        <Grid
          hidden={!SpravatoValues.firstSevereTreatmentResistantMajorDepressiveEpisode}
          item xs={12}>
          <FormControl margin="normal" fullWidth>
            <Typography>{t("spravato.secondRequestingPhysicianWorksInBAGApprovedInstitutionHelperText")}</Typography>
            <Box sx={{m: 1}}/>
            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({
                      formData: {secondRequestingPhysicianIsPsychiatristOrPsychotherapist: event.target.checked},
                    }))
                  }
                  data-cy="secondRequestingPhysicianIsPsychiatristOrPsychotherapist"
                />
              }
              label={t("spravato.secondRequestingPhysicianIsPsychiatristOrPsychotherapist")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({
                      formData: {secondRequestingPhysicianWorksInBAGApprovedInstitution: event.target.checked},
                    }))
                  }
                  data-cy="secondRequestingPhysicianWorksInBAGApprovedInstitution"
                />
              }
              label={t("spravato.secondRequestingPhysicianWorksInBAGApprovedInstitution")}
            />
            <Box sx={{m: 1}}/>
            <FormControl fullWidth>
              <InputLabel id="secondBagApprovedInstitution-label">
                {t("spravato.secondBagApprovedInstitution")}
              </InputLabel>
              <Select
                labelId="secondBagApprovedInstitution-label"
                fullWidth
                value={SpravatoValues.secondBagApprovedInstitution || ""}
                onChange={(event) =>
                  dispatch(onChangeSpravatoValue({
                    formData: {secondBagApprovedInstitution: event.target.value},
                  }))
                }
                data-cy="secondBagApprovedInstitution"
                label={t("spravato.secondBagApprovedInstitution")}
              >
                <MenuItem value="">
              Select an institution
                </MenuItem>
                {bagApprovedInstitutions.map((institution, index) => (
                  <MenuItem key={index} value={institution.trim()}>
                    {institution.trim()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


          </FormControl>
          <Box sx={{m: 1}}/>

        </Grid>
        {
        /*
        3 Part

        thirdBeforeTherapyCommencement: false,
        thirdProgressReviewAfter4Weeks: false,
        thirdProgressReviewAfter4MonthsOfTherapy: false,
        thirdProgressReviewAfter7MonthsofTherapy: false,
        thirdTherapyDurationOf10MonthsOrMore: false,
        */
        }
        <Grid
          hidden={
            !(
              SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
                        SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
                        SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist
            )
          }
          item xs={12}>
          <FormControl fullWidth margin="normal">
            <FormLabel>{t("spravato.third.options")}</FormLabel>
            <RadioGroup
              onChange={(event, value) => {
                if (value === "thirdBeforeTherapyCommencement") {
                  dispatch(onChangeSpravatoValue({formData: {thirdBeforeTherapyCommencement: true}}));
                } else if (value === "thirdProgressReviewAfter4Weeks") {
                  dispatch(onChangeSpravatoValue({formData: {thirdProgressReviewAfter4Weeks: true}}));
                } else if (value === "thirdProgressReviewAfter4MonthsOfTherapy") {
                  dispatch(onChangeSpravatoValue({formData: {thirdProgressReviewAfter4MonthsOfTherapy: true}}));
                } else if (value === "thirdProgressReviewAfter7MonthsofTherapy") {
                  dispatch(onChangeSpravatoValue({formData: {thirdProgressReviewAfter7MonthsofTherapy: true}}));
                } else if (value === "thirdTherapyDurationOf10MonthsOrMore") {
                  dispatch(onChangeSpravatoValue({formData: {thirdTherapyDurationOf10MonthsOrMore: true}}));
                }
              }}>
              <FormControlLabel
                value="thirdBeforeTherapyCommencement"
                control={<Radio
                  checked={SpravatoValues.thirdBeforeTherapyCommencement}
                  data-cy="thirdBeforeTherapyCommencement" />}
                label={t("spravato.thirdBeforeTherapyCommencement")}
              />
              <FormControlLabel
                value="thirdProgressReviewAfter4Weeks"
                control={<Radio
                  checked={SpravatoValues.thirdProgressReviewAfter4Weeks}
                  data-cy="thirdProgressReviewAfter4Weeks" />}
                label={t("spravato.thirdProgressReviewAfter4Weeks")}
              />
              <FormControlLabel
                value="thirdProgressReviewAfter4MonthsOfTherapy"
                control={<Radio
                  checked={SpravatoValues.thirdProgressReviewAfter4MonthsOfTherapy}
                  data-cy="thirdProgressReviewAfter4MonthsOfTherapy" />}
                label={t("spravato.thirdProgressReviewAfter4MonthsOfTherapy")}
              />
              <FormControlLabel
                value="thirdProgressReviewAfter7MonthsofTherapy"
                control={<Radio
                  checked={SpravatoValues.thirdProgressReviewAfter7MonthsofTherapy}
                  data-cy="thirdProgressReviewAfter7MonthsofTherapy" />}
                label={t("spravato.thirdProgressReviewAfter7MonthsofTherapy")}
              />
              <FormControlLabel
                value="thirdTherapyDurationOf10MonthsOrMore"
                control={<Radio
                  checked={SpravatoValues.thirdTherapyDurationOf10MonthsOrMore}
                  data-cy="thirdTherapyDurationOf10MonthsOrMore" />}
                label={t("spravato.thirdTherapyDurationOf10MonthsOrMore")}
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{m: 1}}/>

        </Grid>

        {/*
          4 Part
          fourthPatientIsAged18to74YearsInclusive: false,
          fourthPatientHasPreviouslyReceived2DifferentAntidepressants: false,
          fourthAntidepressiva: "",
          fourthTherapieStart: null,
          fourthTherapieEnd: null,
          fourthAntidepressiva2: "",
          fourthTherapieStart2: null,
          fourthTherapieEnd2: null,

      */}
        <Grid
          hidden={
            !(
              SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
                        SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
                        SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist
            )
          }

          item xs={12}>

          <FormControl fullWidth margin="normal">
            <FormLabel>{t("spravato.fourth.options")}</FormLabel>


            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.fourthPatientIsAged18to74YearsInclusive || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({formData: {
                      fourthPatientIsAged18to74YearsInclusive: event.target.checked}}))}
                  data-cy="fourthPatientIsAged18to74YearsInclusive"
                />
              }
              label={t("spravato.fourthPatientIsAged18to74YearsInclusive")}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.fourthPatientHasPreviouslyReceived2DifferentAntidepressants || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({formData: {
                      fourthPatientHasPreviouslyReceived2DifferentAntidepressants: event.target.checked}}))}
                  data-cy="fourthPatientHasPreviouslyReceived2DifferentAntidepressants"
                />
              }
              label={t("spravato.fourthPatientHasPreviouslyReceived2DifferentAntidepressants")}
            />

            <Typography>{t("spravato.fourthAntidepressivaHelperText")}</Typography>


            {/* Medication Name Field */}
            <DrugPlanForm
              label={t("spravato.fourthAntidepressiva")}
              onChange={(newValue) => dispatch(onChangeSpravatoValue({formData: {fourthAntidepressiva: newValue}}))}
              value={SpravatoValues.fourthAntidepressiva || ""}
            />
            {/* Medication Start Date */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label={t("spravato.fourthTherapieStart")}
                  type="month"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{shrink: true}}
                  onChange={(event) => dispatch(onChangeSpravatoValue({formData: {fourthTherapieStart: event.target.value}}))}
                  value={(
                    SpravatoValues.fourthTherapieStart ||
                  "")
                  }
                  data-cy="fourthTherapieStart"
                />
              </Grid>
              <Grid item xs={6}>
                {/* Medication End Date */}
                <TextField
                  label={t("spravato.fourthTherapieEnd")}
                  type="month"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{shrink: true}}
                  onChange={(event) => dispatch(onChangeSpravatoValue({formData: {fourthTherapieEnd: event.target.value}}))}
                  value={SpravatoValues.fourthTherapieEnd || ""}
                  data-cy="fourthTherapieEnd"
                />
              </Grid>
            </Grid>

            <TextField
              label={t("spravato.fourthAntidepressivaEffect")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(event) => dispatch(onChangeSpravatoValue({formData: {fourthAntidepressivaEffect: event.target.value}}))}
              value={SpravatoValues.fourthAntidepressivaEffect || ""}
              data-cy="fourthAntidepressivaEffect"
            />

            <Box sx={{m: 1}}/>

            <DrugPlanForm
              label={t("spravato.fourthAntidepressiva2")}
              onChange={(newValue) => dispatch(onChangeSpravatoValue({formData: {fourthAntidepressiva2: newValue}}))}
              value={SpravatoValues.fourthAntidepressiva2 || ""}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* Medication Start Date */}
                <TextField
                  label={t("spravato.fourthTherapieStart2")}
                  type="month"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{shrink: true}}
                  onChange={(event) => dispatch(onChangeSpravatoValue({formData: {fourthTherapieStart2: event.target.value}}))}
                  value={SpravatoValues.fourthTherapieStart2 || ""}
                  data-cy="fourthTherapieStart2"
                />
              </Grid>
              <Grid item xs={6}>
                {/* Medication End Date */}
                <TextField
                  label={t("spravato.fourthTherapieEnd2")}
                  type="month"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{shrink: true}}
                  onChange={(event) => dispatch(onChangeSpravatoValue({formData: {fourthTherapieEnd2: event.target.value}}))}
                  value={SpravatoValues.fourthTherapieEnd2 || ""}
                  data-cy="fourthTherapieEnd2"
                />
              </Grid>
            </Grid>
            <TextField
              label={t("spravato.fourthAntidepressivaEffect2")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(event) => dispatch(onChangeSpravatoValue({formData: {fourthAntidepressivaEffect2: event.target.value}}))}
              value={SpravatoValues.fourthAntidepressivaEffect2 || ""}
              data-cy="fourthAntidepressivaEffect2"
            />

          </FormControl>
          <Box sx={{m: 1}}/>
        </Grid>

        {
        /*
        fifth

        fifthPatientHasPreviouslyReceived1Augmentation : boolean,
        fifthAugmentation: string,
        */
        }

        <Grid
          hidden={
            !(
              SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
                        SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
                        SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist
            )
          }

          item xs={12}>

          <FormControl fullWidth margin="normal">
            <FormLabel></FormLabel>

            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.fifthPatientHasPreviouslyReceived1Augmentation || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({formData: {
                      fifthPatientHasPreviouslyReceived1Augmentation: event.target.checked}}))}
                  data-cy="fifthPatientHasPreviouslyReceived1Augmentation"
                />
              }
              label={t("spravato.fifthPatientHasPreviouslyReceived1Augmentation")}
            />
            <TextField
              label={t("spravato.fifthAugmentation")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(event) => dispatch(onChangeSpravatoValue({formData: {fifthAugmentation: event.target.value}}))}
              value={SpravatoValues.fifthAugmentation || ""}
              data-cy="fifthAugmentation"
            />
          </FormControl>
        </Grid>
        {

        /*
        sixth

        sixthCGISBeforeTreatmentWithSPRAVATO5 : boolean,
        sixthCGISBeforeTreatmentWithSPRAVATO: text,
        */
        }
        <Grid
          hidden={
            !(
              SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
                        SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
                        SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist
            )
          }

          item xs={12}>
          <FormControl fullWidth margin="normal">
            <FormLabel></FormLabel>
            {/* Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.sixthCGISBeforeTreatmentWithSPRAVATO5 || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({formData: {
                      sixthCGISBeforeTreatmentWithSPRAVATO5: event.target.checked}}))}
                  data-cy="sixthCGISBeforeTreatmentWithSPRAVATO5"
                />
              }
              label={t("spravato.sixthCGISBeforeTreatmentWithSPRAVATO5")}
            />

            <Typography>{t("spravato.sixthCGISBeforeTreatmentWithSPRAVATO5HelperText")}</Typography>

            <Box sx={{m: 1}}/>

            <FormControl fullWidth>
              <InputLabel id="cgiSLabel">
                {t("spravato.cgiSLabel.default")}
              </InputLabel>
              <Select
                fullWidth
                value={SpravatoValues.sixthCGISBeforeTreatmentWithSPRAVATO || 0}
                onChange={(event) => dispatch(onChangeSpravatoValue(
                    {formData: {sixthCGISBeforeTreatmentWithSPRAVATO: event.target.value}}))}
                data-cy="cgiS-default"
                label={t("spravato.cgiSLabel.default")}
              >
                {cgiSOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>
        </Grid>

        {
          // Begründung für die Anwendung von SPRAVATO
        }
        <Grid
          hidden={
            !(
              SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
                        SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
                        SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist
            )
          }
          item xs={12}>
          <FormControl fullWidth margin="normal">
            <FormLabel></FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({formData: {
                      seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable: event.target.checked}}))}
                  data-cy="seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable"
                />
              }
              label={t("spravato.seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable")}
            />
            <Typography>{t("spravato.seventhReasonsOptionalHelperText")}</Typography>
            <TextField
              label={t("spravato.seventhReasons")}
              variant="outlined"
              margin="normal"
              onChange={(event) => dispatch(onChangeSpravatoValue({formData: {seventhReasons: event.target.value}}))}
              value={SpravatoValues.seventhReasons || ""}
              data-cy="seventhReasons"
            />
          </FormControl>
        </Grid>


        {
        /*
        seventh

        seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable: boolean,
        seventhReasons: string,
        seventhComments: string,
        seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant: string,

        */
        }


        <Grid
          hidden={
            !(
              SpravatoValues.firstAlternativeIndication
            )
          }

          item xs={12}>

          <TextField
            style={{width: "100%"}}
            InputLabelProps={{shrink: true}}
            data-cy="preTreatment"
            label={t("preTreatment")}
            value={SpravatoValues.preTreatment}
            multiline
            maxRows={4}
            minRows={4}
            variant="outlined"
            onChange = {(event ) =>
              dispatch(onChangeSpravatoValue(
                  {
                    preTreatment: (event.target as HTMLInputElement).value,
                  }),
              )
            }
          />
          <Box sx={{m: 1}}/>
          <TextField
            multiline
            data-cy="patientCondition"
            style={{width: "100%"}}
            label={t("patientCondition")}
            InputLabelProps={{shrink: true}}
            value={SpravatoValues.patientCondition }
            variant="outlined"
            maxRows={4}
            minRows={4}
            onChange = {(event ) =>
              dispatch(onChangeSpravatoValue(
                  {
                    patientCondition: (event.target as HTMLInputElement).value,
                  }),
              )
            }
          />

          <Box sx={{m: 1}}/>
          <TextField
            style={{width: "100%"}}
            data-cy="reasonsAlternativeTherapiesFailed"
            label={t("reasonsAlternativeTherapiesFailed")}
            InputLabelProps={{shrink: true}}
            value={SpravatoValues.reasonsAlternativeTherapiesFailed }
            variant="outlined"
            maxRows={4}
            minRows={4}
            multiline
            onChange = {(event ) =>
              dispatch(onChangeSpravatoValue(
                  {
                    reasonsAlternativeTherapiesFailed: (event.target as HTMLInputElement).value,
                  }),
              )
            }
          />
          <Box sx={{m: 1}}/>
          <TextField
            style={{width: "100%"}}
            label={t("prognosis")}
            data-cy="prognosis"
            InputLabelProps={{shrink: true}}
            value={SpravatoValues.prognosis }
            variant="outlined"
            maxRows={4}
            minRows={4}
            multiline
            onChange = {(event ) =>
              dispatch(onChangeSpravatoValue(
                  {
                    prognosis: (event.target as HTMLInputElement).value,
                  }),
              )
            }
          />

        </Grid>

        {
        /*
        eighth
        // IF thirdProgressReviewAfter4MonthsOfTherapy === true

        eightCGISBeforeTreatmentWithSPRAVATO5: boolean,
        eightCGISBeforeTreatmentWithSPRAVATO: string,
        */
        }

        <Grid
          hidden={
            !(
              SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
            SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
            SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist &&
            SpravatoValues.thirdProgressReviewAfter4MonthsOfTherapy
            )
          }
          item xs={12}>
          <FormControl fullWidth margin="normal">
            <FormLabel>{t("spravato.thirdProgressReviewAfter4MonthsOfTherapyCGI.helper")}</FormLabel>
            <FormLabel>{t("spravato.thirdProgressReviewAfter4MonthsOfTherapyCGI")}</FormLabel>
            {/* Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.eightCGISBeforeTreatmentWithSPRAVATO5 || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({formData: {
                      eightCGISBeforeTreatmentWithSPRAVATO5: event.target.checked}}))}
                  data-cy="eightCGISBeforeTreatmentWithSPRAVATO5"
                />
              }
              label={t("spravato.eightCGISBeforeTreatmentWithSPRAVATO5")}
            />

            <Box sx={{m: 1}}/>
            <FormControl fullWidth>
              <InputLabel id="cgiSLabel">
                {t("spravato.cgiSLabelAfter4Month")}
              </InputLabel>
              <Select
                fullWidth
                value={SpravatoValues.eightCGISBeforeTreatmentWithSPRAVATO || 0}
                onChange={(event) => dispatch(onChangeSpravatoValue(
                    {formData: {eightCGISBeforeTreatmentWithSPRAVATO: event.target.value}}))}
                data-cy="cgiS"
                label={t("spravato.cgiSLabelAfter4Month")}
              >
                {cgiSOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>
        </Grid>


        {
        /*
        ninth
        ninthCGICheckBox: boolean,
        ninthCGIS1: string,
        ninthCGIS2: string,

        */
        }
        <Grid
          hidden={
            !(
              SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
            SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
            SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist &&
            SpravatoValues.thirdProgressReviewAfter4Weeks
            )
          }
          item xs={12}>
          <FormControl fullWidth margin="normal">
            <FormLabel>{t("spravato.ninthCGICheckBox.helper")}</FormLabel>
            {/* Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.ninthCGICheckBox || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({formData: {
                      ninthCGICheckBox: event.target.checked}}))}
                  data-cy="ninthCGICheckBox"
                />
              }
              label={t("spravato.ninthCGICheckBox")}
            />

            <Box sx={{m: 1}}/>
            <FormControl fullWidth>
              <InputLabel id="ninthCGIS1">
                {t("spravato.ninthCGIS1")}
              </InputLabel>
              <Select
                fullWidth
                value={SpravatoValues.ninthCGIS1 || 0}
                onChange={(event) => dispatch(onChangeSpravatoValue(
                    {formData: {ninthCGIS1: event.target.value}}))}
                data-cy="cgiS"
                label={t("spravato.ninthCGIS1")}
              >
                {cgiSOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{m: 1}}/>
            <FormControl fullWidth>
              <InputLabel id="ninthCGIS2">
                {t("spravato.ninthCGIS2")}
              </InputLabel>
              <Select
                fullWidth
                value={SpravatoValues.ninthCGIS2 || 0}
                onChange={(event) => dispatch(onChangeSpravatoValue(
                    {formData: {ninthCGIS2: event.target.value}}))}
                data-cy="cgiS"
                label={t("spravato.ninthCGIS2")}
              >
                {cgiSOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>
        </Grid>


        <Grid
          hidden={
            !(
              SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
            SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
            SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist &&
            SpravatoValues.thirdProgressReviewAfter7MonthsofTherapy
            )
          }
          item xs={12}>
          <FormControl fullWidth margin="normal">
            <FormLabel>{t("spravato.tenthCGICheckBox.helper")}</FormLabel>
            {/* Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.tenthCGICheckBox || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({formData: {
                      tenthCGICheckBox: event.target.checked}}))}
                  data-cy="tenthCGICheckBox"
                />
              }
              label={t("spravato.tenthCGICheckBox")}
            />

            <Box sx={{m: 1}}/>
            <FormControl fullWidth>
              <InputLabel id="tenthCGIS1">
                {t("spravato.tenthCGIS1")}
              </InputLabel>
              <Select
                fullWidth
                value={SpravatoValues.tenthCGIS1 || 0}
                onChange={(event) => dispatch(onChangeSpravatoValue(
                    {formData: {tenthCGIS1: event.target.value}}))}
                data-cy="cgiS"
                label={t("spravato.tenthCGIS1")}
              >
                {cgiSOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </FormControl>
        </Grid>
        <Grid
          hidden={
            !(
              SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
            SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
            SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist &&
            SpravatoValues.thirdTherapyDurationOf10MonthsOrMore
            )
          }
          item xs={12}>
          <FormControl fullWidth margin="normal">
            <FormLabel>{t("spravato.eleventhCGICheckBox.helper")}</FormLabel>
            {/* Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={SpravatoValues.eleventhCGICheckBox || false}
                  onChange={(event) =>
                    dispatch(onChangeSpravatoValue({formData: {
                      eleventhCGICheckBox: event.target.checked}}))}
                  data-cy="eleventhCGICheckBox"
                />
              }
              label={t("spravato.eleventhCGICheckBox")}
            />
            <Box sx={{m: 1}}/>
            <FormControl fullWidth>
              <InputLabel id="eleventhCGIS1">
                {t("spravato.eleventhCGIS1")}
              </InputLabel>
              <Select
                fullWidth
                value={SpravatoValues.eleventhCGIS1 || 0}
                onChange={(event) => dispatch(onChangeSpravatoValue(
                    {formData: {eleventhCGIS1: event.target.value}}))}
                data-cy="cgiS"
                label={t("spravato.eleventhCGIS1")}
              >
                {cgiSOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {SpravatoValues.variant && SpravatoValues.default === false && (
          <FormLabel>{t("variant")}: {SpravatoValues.variant}</FormLabel>
        )}
      </Grid>


      <Grid
        hidden={
          !(
            SpravatoValues.secondBagApprovedInstitution?.length > 0 &&
                        SpravatoValues.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
                        SpravatoValues.secondRequestingPhysicianIsPsychiatristOrPsychotherapist
          )
        }

        item xs={12}>

        <FormControl fullWidth margin="normal">

          <Typography>{t("spravato.seventhCommentsOptionalHelperText")}</Typography>
          <TextField
            label={t("spravato.seventhComments")}
            variant="outlined"
            margin="normal"
            onChange={(event) => dispatch(onChangeSpravatoValue({formData: {seventhComments: event.target.value}}))}
            value={SpravatoValues.seventhComments || ""}
            data-cy="seventhComments"
          />

          <Box sx={{m: 1}}/>
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
          >
            <WarningIcon color={"primary"}></WarningIcon>
            <Typography>{t("spravato.seventhCommentsHelperText")}</Typography>
          </Stack>
          <Box sx={{m: 1}}/>
          <Typography>{t("spravato.seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressantHelperText")}</Typography>
          <DrugPlanForm
            label={t("spravato.seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant")}
            onChange={(newValue) =>
              dispatch(onChangeSpravatoValue({formData: {
                seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant: newValue}}))}
            value={SpravatoValues.seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant || ""}
          />
        </FormControl>
      </Grid>
    </LocalizationProvider>

  </>;
};

const mapStateToProps = (state) => {
  return {
    // Map state to props here if needed
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props here if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(SpravatoForm);
